:root {
  --size: 75px;
  --clr-bg: #272324;
  --clr1: #055160;
  --clr2: #0f5132;
  --clr3: #6c757d;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}



.loading_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    min-height: 100vh;
}



.spinner {
  --animation-duration: 5000ms;
  position: relative;
  width: var(--size);
  height: var(--size);
  transform: rotate(45deg);
}
.spinner .spinner-item {
  --item-size: calc(var(--size) / 2.5);
  position: absolute;
  width: var(--item-size);
  height: var(--item-size);
  border: 4px solid var(--clr-spinner);
}
.spinner .spinner-item:nth-child(1) {
  --clr-spinner: var(--clr1);
  top: 0;
  left: 0;
  animation: spinner3A var(--animation-duration) linear infinite;
}
@keyframes spinner3A {
  0%, 8.33%, 16.66%, 100% {
    transform: translate(0%, 0%);
  }
  24.99%, 33.32%, 41.65% {
    transform: translate(100%, 0%);
  }
  49.98%, 58.31%, 66.64% {
    transform: translate(100%, 100%);
  }
  74.97%, 83.30%, 91.63% {
    transform: translate(0%, 100%);
  }
}
.spinner .spinner-item:nth-child(2) {
  --clr-spinner: var(--clr3);
  top: 0;
  left: var(--item-size);
  animation: spinner3B var(--animation-duration) linear infinite;
}
@keyframes spinner3B {
  0%, 8.33%, 91.63%, 100% {
    transform: translate(0%, 0%);
  }
  16.66%, 24.99%, 33.32% {
    transform: translate(0%, 100%);
  }
  41.65%, 49.98%, 58.31% {
    transform: translate(-100%, 100%);
  }
  66.64%, 74.97%, 83.30% {
    transform: translate(-100%, 0%);
  }
}
.spinner .spinner-item:nth-child(3) {
  --clr-spinner: var(--clr2);
  top: var(--item-size);
  left: var(--item-size);
  animation: spinner3C var(--animation-duration) linear infinite;
}
@keyframes spinner3C {
  0%, 83.30%, 91.63%, 100% {
    transform: translate(0, 0);
  }
  8.33%, 16.66%, 24.99% {
    transform: translate(-100%, 0);
  }
  33.32%, 41.65%, 49.98% {
    transform: translate(-100%, -100%);
  }
  58.31%, 66.64%, 74.97% {
    transform: translate(0, -100%);
  }
}

