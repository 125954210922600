.regular_batch_dashboard_wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}

.demo_dashboard{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: #ffffff;
    width: 40%;
    margin: 60px 40px;
    box-shadow: 1px 1px 14px lightgrey;
    border-radius: 8px;
    overflow: hidden;
}

.demo_details_main_heading{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    background-color: #282945;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 1px 1px 14px lightgrey;
}

.demo_details_main_heading h1{
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 24px;
    text-align: left;
    color: #ffffff;
}

.demo_details_card_wrapper{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  padding: 10px;
  gap: 15px;
}

.demo_details_mainwrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.demo_details_heading{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    border-bottom: 1px solid #282945;
}

.demo_details_heading h2{
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 24px;
    text-align: left;
    color: #282945;
    text-transform: uppercase;
}

.demo_details_subwrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 20px 0px;
    gap: 10px;
}

.demo_details_subwrapper p{
    margin: 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 24px;
    text-align: left;
    color: #282945;
    text-transform: uppercase;
}

.demo_details_subwrapper p span{
    margin: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    text-align: left;
    color: #000000;
}

#slot{
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 20px;
    border: 1px solid black;
    margin-right: 10px;
}

.demo_details_subwrapper_one{
    display: flex;
    width: 70%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap:10px;
}

.demo_details_subwrapper_two{
    display: flex;
    width: 30%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap:10px;  
    margin-top: auto;
}

.demoDetailsIcon{
    width: 20px;
    height: 20px;
    margin: 0px;
    margin-right: 10px;
    color: #282945;
}

.demo_details_specific{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.activeStatusButton{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 100%;
    border-radius: 8px;
    color: #ffffff;
    background: #2fac34;
    font-size: 14px;
}

.activeStatusButton:hover{
    transform: translateY(-4px);
    transition: 0.2s ease-in-out;
}

.disabledStatusButton{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 100%;
    border-radius: 8px;
    color: #000000;
    background: #c8c2c3;
    cursor: not-allowed !important;
    font-size: 14px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
   .demo_dashboard{
    width: 100%;
    margin: 60px 10px;
   }

   .demo_details_subwrapper{
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 10px 0px;

   }

   .demo_details_subwrapper_one{
     width: 100%;
   }

   .demo_details_subwrapper_two{
     width: 100%;
   }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    /* .regular_batch_dashboard_wrapper{
        flex-direction: column;
    } */
   .demo_dashboard{
    width: 100%;
    margin: 60px 10px;
   }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
   .demo_dashboard{
    width: 100%;
    margin: 60px 10px;
   }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}