.card_wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: #fdfdfe;
    color: #000;
    width: 100%;
    border-radius: 8px;
    box-shadow: 1px 1px 14px lightgrey;
    overflow: hidden;
    border-left: 10px solid #282945;
}


.card_content{
    display: flex;
    align-items: center;
    justify-content:space-between;
    padding: 10px;
    width: 100%; 
}

.card_wrapper:hover{
  background: rgba(214, 214, 214, 0.808);
  transition: 0.2s ease-in-out;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
.card_wrapper_sidestyle{
  height: 242px;
}
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}
