.otpPopUpWrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.prShow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}
.prInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
 border-radius: 21.7561px;
  margin: 100px 0;
  width: 446px;
  left: 15px;
  padding: 30px 45px;
  gap: 10px;
box-shadow: 0px 5.43902px 27.1951px rgba(17, 58, 104, 0.18);

}

.otpPopUpHeader{
  display: flex;
  align-items: center;
  width: 100%;
}

.closeButtonWrapper{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: 80px;
}

.otpPopUpWrapper img{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
}

.otpPopUpBrandLogo{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 12px;
}

.otpBoxDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 10px 20px;
}

.otpBoxDetails h1{
  font-style: normal;
  font-weight: 300;
  font-size: 27.1951px;
  line-height: 108%;
  text-align: center;
  color: #313131;
}



.otpBoxDetailsEdit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
}

.otpBoxDetailsEdit p {
  font-style: normal;
  font-weight: 400;
  font-size: 21.7561px;
  line-height: 108%;
  text-align: center;
  color: #313131;
  margin: 0;
}

.faEditIcon {
  height: 20px;
}

.faClose {
  height: 20px;
}

.faEditIcon:hover{
    cursor: pointer;
}

.faClose:hover{
    cursor: pointer;
}

.otpField {
  overflow: hidden;
  width: 82px;
  height: 87px;
  border: 1.35976px solid #D8D8D8;
border-radius: 10.878px;
border-bottom: 5.43902px solid #FF3A58;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    text-align: center;
}


.otpInputWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 10px 20px;
}

.resendOtpWrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}


.resendOtpWrapper #timerWrapper{
  font-style: normal;
  font-weight: 500;
  font-size: 16.3171px;
  line-height: 20px;
  text-align: right;
  color: rgba(49, 49, 49, 0.4);
  margin-bottom: 0;
  margin-left: auto;
}

.resendOtpWrapper #error_msg{
    font-style: normal;
  font-weight: 600;
  font-size: 16.3171px;
  line-height: 20px;
  text-align: right;
  color: #FF0000;
  margin-bottom: 0;
}

#resendOtpButton{
    outline: none !important;
    border: none !important;
    background: transparent;
    align-items: flex-start;
    justify-content: flex-start;
    font-style: normal;
    font-weight: 500;
    font-size: 16.3171px;
    line-height: 20px;
    color: #3366CC;
    margin-left: auto;
}

.otpButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
  margin: 10px 20px;
}

.otpButtonWrapperClear{
  background-image: linear-gradient(90deg, rgba(13,15,30,1) 0%, rgba(17,20,45,1) 35%, rgba(0,0,1,1) 100%);;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  display: block;
  padding: 10px 20px;
  border-radius: 4px;
}

.otpButtonWrapperVerify{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #FF3A58;
  border-radius: 10.8781px;
  outline: none;
  border: none;
  text-transform: capitalize;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 800;
  font-size: 27.1951px;
  line-height: 33px;
  padding: 25px 0px;
}

.otpButtonWrapperClear:hover {
  background-position: right center; /* change the direction of the color here */
  color: #fff;
  text-decoration: none;
}

.otpButtonWrapperVerify:hover {
  background-position: right center; /* change the direction of the color here */
  color: #fff;
  text-decoration: none;
}

/* Media Queries */

@media screen and (max-width: 480px) {

  .prInner{
    width: 335px;
  }

  .otpField{
    width: 54px;
    height: 64px;
  }

  .closeButtonWrapper{
    margin-right: 34px;
  }

}