.dashboardHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #282945;
    padding: 8px 10px;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    position: fixed;
    z-index: 2;
}

.dashboardContentLeft{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100px;
}

.dashboardContent{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.dashboardHamburger{
    color: #ffffff;
    cursor: pointer;
}

.dashboardLogo img{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
}
.sidebar{
    position: absolute;
    width: 300px;
    height: 100vh;
    top:0;
    left:-300px;
    background-color:black;
    z-index: 2;   
    transition: all 0.5s ease-in;
}
.active{
    position: absolute;
    width: 300px;
    height: 100vh;
    top:0;
    background-color:#282945;
    z-index: 2;   
    transition: all 0.5s ease-in;
    left:0px;
}
.activebackdrop{
    position: absolute;
    height: 100%;
    width:100%;
    z-index: 1;
    background-color: (255,255,255,0.1);
}
.backdrop{
    display: none;
}

.sidebarContent{
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: center;
    padding: 20px;
}

.sidebarContentMain{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.sidebarContentSub{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 0px;
}

.sideBarCloseBtn{
    position: relative;
    color: #ffffff;
    cursor: pointer;
    z-index: 2;
}

.logoutBtn{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 8px 10px;
    border-radius: 8px;
    background-color: #404bd9;
    color: #ffffff;
    outline: none;
    border: none;
    cursor: pointer;
    width: 100px;
}

.logoutBtn:hover{
    transform: translateY(-1px);
    transition: all 0.4s;
}

.headerItems{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 170px;
}

.headerItems p{
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
}

.sidebarContentMainTwo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 40px;
}

.sidebarProfileIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
    color: #ffffff;
}

.sidebarContentMainTwo p{
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700; 
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .dashboardContent{
        display: none;
    }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {

}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}