.loginMain{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.loginMainWrapper{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

.loginMainOne{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 194px 255px; 
}

.loginLeftWrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50%;   
}

.loginRightWrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50%;  
}

.loginLeftWrapper h1{
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 108%;
    color: #333333;
}

.loginBrandLogo{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 58px;
    margin-bottom: 20px;
}

.loginMain img{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
}

.loginBrandImage{
    margin-top: 38px;
}

.loginForm{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 41px 26px 38px 26px;
    background: #FFFFFF;
    box-shadow: 0px 5.43903px 27.1951px rgba(17, 58, 104, 0.18);
    border-radius: 21.7561px;
}

.loginFormBrandLogo{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 106px;
    height: 12px;
    margin-bottom: 28px;
}

#sectionSeparated{
    font-style: normal;
    font-weight: 400;
    font-size: 16.3171px;
    line-height: 108%;
    text-align: center;
    color: #313131;
    margin-top: 20px;
}

.formInputs{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 16.3171px;
    line-height: 20px;
    border: 1.5274px solid rgba(49, 49, 49, 0.2);
    border-radius: 10.8781px;
    margin-bottom: 10px;
}

.formInputs::placeholder,.formInputs::-moz-placeholder,.formInputs::-webkit-input-placeholder{
    font-style: normal;
    font-weight: 500;
    font-size: 16.3171px;
    line-height: 20px;
    text-align: center;
    color: rgba(49, 49, 49, 0.4);
}

.loginForm h2{
    font-style: normal;
    font-weight: 300;
    font-size: 27.1951px;
    line-height: 108%;
    text-align: center;
    color: #313131;
}

.loginForm p{
    font-style: normal;
    font-weight: 400;
    font-size: 21.7561px;
    line-height: 108%;
    text-align: center;
    color: #313131;
}

.formButtons{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: transparent;
    border: 1.35976px solid #D8D8D8 !important;
    border-radius: 6.79878px !important;
    padding: 10px 0px;
}

.loginForm button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: transparent;
    border: 1.35976px solid #D8D8D8 !important;
    border-radius: 6.79878px !important;
    padding: 10px 0px;  
}

#toggleEmailButton{
    outline: none !important;
    border: none !important;
    background: transparent;
    align-items: flex-start;
    justify-content: flex-start;
    font-style: normal;
    font-weight: 500;
    font-size: 16.3171px;
    line-height: 20px;
    color: #3366CC;
}

#joinClassroomButton{
    background-color: #FF3A58;
    text-transform: capitalize;
color: #FFFFFF;
font-style: normal;
font-weight: 800;
font-size: 27.2px;
line-height: 33px;
padding: 25px 0px;
}

.joinClassroomIcon{
    margin-right: 15px;
}


/* Media Queries */

@media screen and (max-width: 480px) {

    .loginMain{
        height: auto;
    }

    .loginMainOne{
        padding: 10px;

    }

    .loginMainWrapper{
        flex-direction: column;
        gap: 20px;
    }

    .loginLeftWrapper{
        width: 100%;
    }

    .loginRightWrapper{
        width: 100%;
    }

}

@media screen and (max-width: 768px) {
        .loginMain{
        height: auto;
    }

    .loginMainOne{
        padding: 30px;

    }

    .loginMainWrapper{
        flex-direction: column;
        gap: 20px;
    }


    .loginRightWrapper{
        width: 100%;
    }

}

@media screen and (max-width: 1024px) {
            .loginMain{
        height: auto;
    }

    .loginMainOne{
        padding: 30px;

    }

    .loginMainWrapper{
        flex-direction: column;
        gap: 20px;
    }


    .loginRightWrapper{
        width: 100%;
    }

}

@media screen and (max-width: 1200px) {

}

@media screen and (max-width: 1201px) {

}