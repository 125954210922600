.signUpWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.prShow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}
.prInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  border-radius: 10px;
  margin: 100px 0;
  height: 100%;
  left: 15px;
  border-radius: 5px;
  gap: 10px;
}

.signUpWrapperOne {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding: 40px 90px;
  width: 442px;
  max-width: 442px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 20px;
  border-radius: 10px;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.formWrapper button, .signUpWrapperOne button {
  margin: 10px 0px;
  padding: 0;
  border-radius: 0.25em;
  width: 100%;
  font-weight: normal;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #d2d8d8;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.buttonWrapperOne{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding:4px;
    border-radius: 8px;
    margin-bottom: 8px;
    background: #3b3b3b;
}

.buttonWrapperOne button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%; 
    margin: 0px;
    color: #ffffff;
    border: none;
}

#selectedButton{
    border-radius: 8px;
    padding: 12px 50px;
    background: #0d0f1e;
    transition: all 1s;
}

#notSelectedButton{
    padding: 12px 50px;
    background: #3b3b3b;
    transition: all 1s;
}

#facebookLoginButton {
  background: #3f51b5;
  color: #ffffff;
}

#oneLastStepButton {
  background-image: linear-gradient(90deg, rgba(13,15,30,1) 0%, rgba(17,20,45,1) 35%, rgba(0,0,1,1) 100%);;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  display: block;
}

#oneLastStepButton:hover {
  background-position: right center; /* change the direction of the color here */
  color: #fff;
  text-decoration: none;
}

.formWrapper label {
  color: #000000;
}

.formWrapper input {
    display: flex;
    align-items: center;
    justify-content: center;
  margin: 10px 0px;
  border-radius: 0.25em;
  width: 100%;
  font-weight: normal;
  background-color: #ffffff;
  color: #000000;
  padding: 12px 38px;
  border: 1px solid #d2d8d8;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.formWrapper input:focus {
  border-color: #343642;
  box-shadow: 0 0 5px rgba(52, 54, 66, 0.1);
  outline: none;
}

.formWrapper > span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8px 10px;
}

