@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,500;1,700;1,900&display=swap');

:root {
    --primary-color: #007bff;
    --primary-color-light: #057FFF;
    --font-light-color: #a4acc4;
    --font-dark-color: #313131;
    --font-dark-color-2: #151515;
    --light-blue-color: #d8f1ff;
    --white-color: #ffffff;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
}


body::-webkit-scrollbar {
    width: 6px;
    background-color: #69696949;
}

body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #6b6b6b;
}

body::-webkit-scrollbar-track {
    background-color: #474747;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

button {
    border: none;
    outline: none;
    cursor: pointer;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 8px;
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    padding: 0rem 1.5rem;
}

p {
    margin: 0;
    padding: 0;
}